<template>
  <div class="" v-if="design">
    <div class="checkout-main-section-timer__reservation-people-timer" v-if="reservation.people">
      <p class="checkout-main-section-timer__reservation-people-timer__title">{{ (isShowRequestReservation) ? $t('confirmReservationRequest') : $t('confirmReservation') }}</p>
      <span :style="{color:colorCronometer}" class="checkout-main-section-timer__reservation-people-timer__timer">{{ timer }}</span>
      <iconTime :color="colorCronometer" class="checkout-main-section-timer__reservation-people-timer__icon-timer" size="1.5rem"></iconTime>
    </div>
    <div class="checkout-main-section">
      <div class="checkout-main-section-content">
        <div class="checkout-main-section-content__main-title">
          <h6 class="checkout-main-section-content__main-title__title">¡{{ (isShowRequestReservation) ? $t('littleCompleteReservationRequest') : $t('littleCompleteReservation') }}!</h6>
          <span class="checkout-main-section-content__main-title__description">{{ (isShowRequestReservation) ? $t('verifyDataRequest') : $t('verifyData') }}</span>
        </div>
        <form :class="{'checkout-main-section-content__form': design.colorCheckout }" class="" v-if="reservation.people">
          <div class="checkout-main-section-content__form__data-client">
            <header class="" :style="[{ background: getDarkColor }]">
              <iconPeople :color="design.colorCheckout" size="1rem"></iconPeople>
              <h6 class="">{{$t('clientInformation')}}</h6>
            </header>
            <div class="checkout-main-section-content__form__data-client__fields">
              <div class="checkout-main-section-content__form__data-client__fields__colums-2">
                <!-- Client name -->
                <b-field class="checkout-main-section-content__form__data-client__fields__general-inputs w-50c aux-w-tablet__name" :message="errors.first('name')" :type="{'is-danger': errors.has('name')}">
                  <b-input
                    @blur="updatePeople"
                    class=""
                    expanded
                    icon-pack="fa"
                    :placeholder="$t('name')"
                    name="name"
                    type="text"
                    v-model="userData.name"
                    v-validate="'alpha_spaces|required|min:2'"
                  ></b-input>
                </b-field>
                <!-- Phone and country -->
                <div class="relative-aux checkout-main-section-content__form__data-client__fields__general-inputs w-50c aux-w-tablet__phone" style="padding: 0;">
                  <b-field>
                    <inputSelector
                      :flag="userLogged.bandera"
                      :initInputValue="userData.phone"
                      :initIndicativo="reservation.indicativo ? parseInt(reservation.indicativo) : 57"
                      @value="changedValuePhone($event)"
                      :required="true"
                    />
                    <p class="help is-danger phone-aux-validation" style="padding-left: 15px;margin-top:1px!important;" v-if="validateTel">{{validateTel}}</p>
                  </b-field>
                  <span class="icon is-right has-text-danger custom-inputs-data-client" v-if="validateTel"><i class="fa fa-exclamation-circle"></i></span>
                </div>
              </div>
              <div :class="`${params.enableIdentityDocument || params.activeBirthDate ? 'checkout-main-section-content__form__data-client__fields__colums-2' : ''}`">
                <!-- Email -->
                <div :style="`${(params.enableIdentityDocument && params.activeBirthDate) || (params.legalPersonSelector || (((vendor.IsActiveSMSSelect == 1 || vendor.isActiveWhatsAppSelect == 1) && isColombia) || params.enableSuscribed)) ? 'margin-bottom: 0px;': ''}width: 100%;max-width: 100%;`" :class="`${(params.enableIdentityDocument || !params.activeBirthDate) || (!params.enableIdentityDocument || params.activeBirthDate)  ? 'w-70c': ''} aux-email-field-with-text`">
                  <b-field style="margin-bottom:2px!important" :message="errors.first('mail')" :type="{'is-danger': errors.has('mail')}" class="checkout-main-section-content__form__data-client__fields__general-inputs">
                    <i class="icon-mks icon_mail_outlined checkout-main-section-content__bg-general-icons"></i>
                    <b-input
                      :placeholder="$t('email')"
                      @blur="updatePeople"
                      @keydown.native.space.prevent
                      class="form-control form-control-sm"
                      expanded
                      icon-pack="fa"
                      id="emailTest"
                      name="mail"
                      type="text"
                      v-model="userData.email"
                      v-validate="'required|email'"
                    ></b-input>
                  </b-field>
                  <div style="margin-top:-4px;">
                    <small class="small-message" style="font-size: 10px;" v-if="(!vendor.IsActiveSMSSelect || !vendor.isActiveWhatsAppSelect) || !isColombia">*{{$t('informationReservation')}}.</small>
                  </div>
                </div>
                <!-- Identity document -->
                <div :class="`relative-aux checkout-main-section-content__form__data-client__fields__general-inputs document-identity-aux ${params.enableIdentityDocument ? 'w-30c': ''}`" v-if="params.enableIdentityDocument">
                  <b-field>
                    <InputC
                      :initTypeDocument="userData.documentType"
                      :options="identificationTypes"
                      :placeHolder="$t('idNumber')"
                      :valueInput="'' + userData.identityDocument ? userData.identityDocument : ''"
                      @valueChange="changedValueIdentity"
                      @blurInput="updatePeople"
                      selector
                      :required="true"
                    />
                    <p class="help is-danger doc-aux-validation" style="margin-top:7px!important;" v-if="validateDoc">{{validateDoc}}</p>
                  </b-field>
                  <span class="icon is-right has-text-danger custom-inputs-data-client" v-if="validateDoc"><i class="fa fa-exclamation-circle"></i></span>
                </div>
                <!-- Birthday date -->
                <b-field :class="`checkout-main-section-content__form__data-client__fields__general-date ${params.activeBirthDate ? 'w-30c': ''}`" v-if="params.activeBirthDate && !params.enableIdentityDocument">
                  <div class="container-icon-birthday">
                    <i class="icon-mks icon_birthday_checkout checkout-main-section-content__bg-general-icons"></i>
                  </div>
                  <v-date-picker locale="es" :showPopover="false" v-if="countForShow" class="calendar_poll" v-model="birthDate" :max-date="new Date(new Date().getFullYear() - 5, 0, 1)">
                    <template v-slot="{ inputValue, togglePopover }">
                      <input
                        class=""
                        :value="inputValue"
                        @blur="generateBirthDate"
                        :placeholder="$t('dayMonthYear')"
                        readonly="readonly"
                        @click="togglePopover()"
                      />
                      <i class="icon-mks icon_calendar" @click="togglePopover()"></i>
                    </template>
                  </v-date-picker>
                </b-field>
                <!-- Address -->
                <b-field v-if="params.enableAddress" class="checkout-main-section-content__form__data-client__fields__general-inputs w-30c address-field-fix"
                  :message="errors.first('address_checkout')"
                  :type="{'is-danger': errors.has('address_checkout')}"
                >
                  <b-input
                    :placeholder="$t('address')"
                    @blur="updatePeople"
                    class=""
                    expanded
                    autocomplete="off"
                    icon-pack="fa"
                    name="address_checkout"
                    type="text"
                    v-model="userData.address"
                    v-validate="'required'"
                  ></b-input>
                </b-field>
              </div>
              <div :style="`${(params.enableIdentityDocument && params.activeBirthDate && params.enableAddress) || activeOptionsNumber == 0 ? '' : ''}`" :class="`${params.enableIdentityDocument && params.activeBirthDate ? 'checkout-main-section-content__form__data-client__fields__colums-2' : ''}`">
                <!-- Comments -->
                <b-field v-if="(params.showComment && activeOptionsNumber == 0) && vendor.id !== 60" :class="`checkout-main-section-content__form__data-client__fields__general-inputs fix-textarea ${ params.enableIdentityDocument && params.activeBirthDate ? 'w-70c': ''}`"
                  :message="errors.first('comments')"
                  :type="{'is-danger': errors.has('comments')}">
                  <textarea
                    :placeholder="isAndresDc() ? '¿Quieres dejarnos alguna observación especial para tu reserva?' : $t('comments')"
                    :value="userData.comments"
                    @input="validateInputComments"
                    class=""
                    expanded
                    icon-pack="fa"
                    name="comments"
                    type="text"
                  />
                </b-field>
                <b-field :class="`aux-w-tablet__phone checkout-main-section-content__form__data-client__fields__general-date ${params.showComment && activeOptionsNumber == 0 ? 'w-30c': 'w-50c'}`" v-if="params.enableIdentityDocument && params.activeBirthDate">
                  <div class="container-icon-birthday">
                    <i class="icon-mks icon_birthday_checkout checkout-main-section-content__bg-general-icons"></i>
                  </div>
                  <v-date-picker locale="es" :showPopover="false" v-if="countForShow" class="calendar_poll" v-model="birthDate" :max-date="new Date(new Date().getFullYear() - 5, 0, 1)">
                    <template v-slot="{ inputValue, togglePopover }">
                      <input
                        class=""
                        :value="inputValue"
                        @blur="generateBirthDate"
                        :placeholder="$t('dayMonthYear')"
                        readonly="readonly"
                        @click="togglePopover()"
                      />
                      <i class="icon-mks icon_calendar" @click="togglePopover()"></i>
                    </template>
                  </v-date-picker>
                </b-field>
              </div>
              <!-- Legal person -->
              <b-field :label="$t('legalPersonLabel')"
                class="checkout-main-section-content__form__data-client__fields__general-checks"
                horizontal
                v-if="params.legalPersonSelector"
              >
                <b-checkbox id="legalPersonCheck" v-model="isLegalPerson"></b-checkbox>
                <b-field
                  :message="errors.first('legalPersonName')"
                  :type="{'is-danger': errors.has('legalPersonName')}"
                  v-if="isLegalPerson"
                >
                  <b-input
                    :placeholder="$t('companyName')"
                    class=""
                    expanded
                    icon-pack="fa"
                    name="legalPersonName"
                    placeholger
                    type="text"
                    v-model="reservation.legalPersonName"
                    v-validate="'required|min:2'"
                  ></b-input>
                </b-field>
              </b-field>
              <!-- Subscriptions -->
              <b-field style="margin-top: 10px;margin-bottom:0!important;" class="terms checkout-main-section-content__form__data-client__fields__general-checks subscribe-reservation" v-if="params.enableSuscribed">
                <div>
                  <b-checkbox
                    @click="openModalSubcribe"
                    @input="updatePeople"
                    id="exampleCheck1"
                    v-model="userData.subscribed"
                  >
                    <div>
                      <b>{{$t('suscribTo')}}</b> : &nbsp;<b v-if="userData.subscribed">"{{ subcribe_option}}"</b>&nbsp;&nbsp;{{$t('acceptInfoEvents')}}
                    </div>
                  </b-checkbox>
                </div>
              </b-field>
              <!-- Notifications -->
              <div class="checkout-main-section-content__form__data-client__fields__notifications-type-receive" v-if="(vendor.IsActiveSMSSelect == 1 || vendor.isActiveWhatsAppSelect == 1) && isColombia">
                <div>
                  <h3 class="checkout-main-section-content__form__data-client__fields__notifications-type-receive__title">{{$t('notificationReservationQ')}}</h3>
                  <h3 class="checkout-main-section-content__form__data-client__fields__notifications-type-receive__title-responsive">{{$t('notificationReservationQResponsive')}}</h3>
                  <span class="checkout-main-section-content__form__data-client__fields__notifications-type-receive__copy-remember" v-if="reservation.notification === 'mail'">{{$t('notificationReservationRememberEmail')}}</span>
                  <span class="checkout-main-section-content__form__data-client__fields__notifications-type-receive__copy-remember" v-if="reservation.notification === 'sms'">{{$t('notificationReservationRememberSMS')}}</span>
                </div>
                <div
                  class="checkout-main-section-content__form__data-client__fields__notifications-type-receive__checks general-checks-svg-color"
                >
                  <b-checkbox class="general-checks-svg-color__email" v-model="emailNotificactionCheck" @input="handleSelectionNotification('mail')">{{$t('email')}}</b-checkbox>
                  <b-checkbox class="general-checks-svg-color__sms" v-if="vendor.IsActiveSMSSelect == 1 && !isShowRequestReservation" v-model="smsNotificactionCheck" @input="handleSelectionNotification('sms')">{{$t('sms')}}</b-checkbox>
                  <b-checkbox class="general-checks-svg-color__wssp" v-if="vendor.isActiveWhatsAppSelect == 1" v-model="whatsappNotificactionCheck" @input="handleSelectionNotification('whatsapp')">{{$t('whatsapp')}}</b-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="checkout-main-section-content__form__data-reservation">
            <header class="" :style="[{ background: getDarkColor }]">
              <iconReservation :color="design.colorCheckout" size="21px"></iconReservation>
              <h6 class="tab-title">{{$t('bookingInformation')}}</h6>
            </header>
            <section :class="`checkout-main-section-content__form__data-reservation__cards ${ reservation.boy > 0 || reservation.baby > 0 ? 'gap-fix':''}`">
              <div class="checkout-main-section-content__form__data-reservation__cards__card reservation-info-person" v-if="reservation.boy == 0 && reservation.baby == 0">
                <h6 class=""> {{$t('diners')}}</h6>
                <i class="icon-mks icon_people_checkout"></i>
                <h6 class="">{{reservation.people}}</h6>
              </div>
              <div :class="`checkout-main-section-content__form__data-reservation__cards__card ${reservation.boy > 0 || reservation.baby > 0 ? 'reservation-info-flex': ''}`" v-else>
                <article class="">
                  <i class="icon-mks icon_people_checkout"></i>
                  <span class="">{{reservation.people - reservation.boy - reservation.baby}}</span>
                  <span class=""> {{$t('adults')}}</span>
                </article>
                <article :class="`${ reservation.boy ?'':''}`" v-if="reservation.boy > 0">
                  <i class="icon-mks icon_kids"></i>
                  <span class="">{{reservation.boy}}</span>
                  <span class=""> {{$t('kids')}}</span>
                </article>
                <article class="" v-if="reservation.baby > 0">
                  <i class="icon-mks icon_babys"></i>
                  <span class="">{{reservation.baby}}</span>
                  <span class=""> {{$t('babys')}}</span>
                </article>
              </div>
              <div class="checkout-main-section-content__form__data-reservation__cards__card reservation-date">
                <h6 class=""> {{$t('date')}}</h6>
                <i class="icon-mks icon_calendar_checkout icon-item"></i>
                <h6 class="">
                  {{ new Date(reservation.dateTime) | moment('timezone', vendorTimezone, "DD/MMM/YY") }}
                </h6>
              </div>
              <div class="checkout-main-section-content__form__data-reservation__cards__card reservation-time">
                <h6 class=""> {{$t('hour')}}</h6>
                <i class="icon-mks icon_hour_checkout icon-item"></i>
                <h6 class="" >
                  {{ new Date(reservation.dateTime) | moment('timezone', vendorTimezone, "hh:mm A") }}
                </h6>
              </div>
            </section>

          </div>
        </form>
        <!-- Customize Reservation -->
        <b-collapse class="checkout-main-section-content__personalize_reservation" v-if="activeOptionsNumber > 0"
          animation="slide"
          aria-id="customizeReservationOptions">
          <template #trigger="props">
            <div class="" role="button" aria-controls="customizeReservationOptions" :aria-expanded="props.open" :style="[{ background: getDarkColor }]">
              <iconReservation :color="design.colorCheckout" size="21px" class="reservation-icon"></iconReservation>
              <p class=""> {{ $t('customizeReservation') }} </p>
              <span class="header-icon-container"><i class="icon-mks icon_arrow_left" :class="props.open ? 'open' : 'closed'"></i></span>
            </div>
          </template>
          <div class="checkout-main-section-content__personalize_reservation__cards">
            <!-- Pets   -->
            <section class="card-pets"
              v-if="params.isActivePets && params.isActivePets === 1"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="">
                <i class="icon-mks icon_pet"></i>
                <span class="">{{$t('comments_pet')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !reservation.hasPets}"></i>
              </header>
              <div class="card-pets__body">
                <div class="block">
                  <b-radio class="colorRadio" type="is-precompro" v-model="reservation.hasPets" name="withPet" :native-value="true">
                    {{ $t('pollQrView.text4') }}
                  </b-radio>
                  <b-radio class="colorRadio" type="is-precompro" v-model="reservation.hasPets" name="withPet" :native-value="false">
                    {{ $t('pollQrView.text5') }}
                  </b-radio>
                </div>
              </div>
            </section>
            <!-- Main plates -->
            <section class="card-main-plates"
              v-if="(applyStrong && params.activeMainPlate)"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
              >
              <header class="">
                <i class="icon-mks icon_plate main-icon"></i>
                <span class="">{{$t('addMainPlates')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !mainPlateState}"></i>
                <b-switch
                  :checked="mainPlateState"
                  :disabled="disabledMainPlate"
                  :value="mainPlateState"
                  @click="mainPlateState"
                  @input="modalShowMainPlate()"
                  name="mainPlates"
                  type="is-custom"
                  v-model="mainPlateState"
                  size="is-small"
                  class="cursor-pointer"
                ></b-switch>
              </header>
              <p class="">{{$t('rememberChoosePlate1')}} {{ reservation.people }} {{$t('rememberChoosePlate2')}}</p>
              <p class="" v-if="mainPlateState && reservation.mainPlates">{{$t('selectedMainPlate')}}:</p>
              <ul class="" v-if="mainPlateState && reservation.mainPlates">
                <li v-for="(item, index) in reservation.mainPlates" :key="'personInfoPlate-'+index" class="">
                  {{$t('person')}} {{ item.person}}: {{ searchPlateName(item.plate) }}
                </li>
              </ul>
            </section>
            <!-- Experiences -->
            <section class="card-experiences cursor-pointer" v-if="listExperiencePerson.length > 0 && params.activeExperiencies && reValidateShowExperience()"
              @click="modalShowExperience(true)"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="">
                <i class="icon-mks icon_add_checkout secondary-icon"></i>
                <i class="icon-mks icon_plate_checkout main-icon"></i>
                <span class="">{{$t('addExperience')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !reservation.experiences}"></i>
              </header>
              <p class="">{{ params.titleExperience }}</p>
              <p class="">{{ params.descriptionExperience }}</p>
              <ul class="t" v-if="reservation.experiences">
                <li v-for="exp in uniqueExperiences" :key="`${exp.experience}-${exp.person}`" class="">
                  P{{ exp.person }}: {{ exp.name }}
                </li>
              </ul>
            </section>
            <!-- Decorations -->
            <section
              class="card-decoration cursor-pointer"
              v-if="params.decorationActive" @click="!reservation.decorationId && (decoration = true, modalDecoration())"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment), 'cursor-not-allowed' : reservation.decorationId}"
            >
              <header class="custom-option-header">
                <i class="icon-mks icon_add_checkout secondary-icon"></i>
                <i class="icon-mks icon_decoration main-icon"></i>
                <span class="custom-option-title">{{$t('addDecoration')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !reservation.decoration}"></i>
              </header>
              <p class="custom-option-text" v-if="params.decorationTitle" v-html="params.decorationTitle"></p>
              <ul class="custom-option-list" v-if="decorationDescription !== null">
                <li class="custom-option-text">
                  <b>{{decorationDescription}}</b>
                </li>
              </ul>
              <ul class="custom-option-list" v-if="reservation.decorations">
                <li v-for="dec in reservation.decorations" :key="dec.id" class="custom-option-text">
                  <b>{{ dec.name }}</b>
                </li>
              </ul>
              <p class="custom-option-text custom-option-description" v-if="params.decorationDescription && (decorationDescription === null || (reservation.decorations && reservation.decorations.length > 0))" v-html="params.decorationDescription"></p>
            </section>
            <!-- Client Type -->
            <section class="card-client-type" v-if="params.activeSelectorClient == '1' && clientTypes.length > 0"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="custom-option-header">
                <span class="custom-option-title">{{$t('typeCustomer')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !clientType}"></i>
              </header>
              <div class="client-types-container">
                <article class="client-type-option">
                  <b-radio
                    :key="client.id"
                    :native-value="client.name"
                    class="colorRadio"
                    name="clientType"
                    type="is-precompro"
                    v-for="(client) in client"
                    v-model="clientType"
                    v-validate="'required'"
                  >{{client.name}}</b-radio>
                </article>
                <div class="client-guest-input" v-if="clientType != null && isActiveHuesped == 1">
                  <b-field
                    :label="extraDescription"
                    :message="errors.first('huesped')"
                    :type="{ 'is-danger': errors.has('huesped') }"
                    class="numHabitacion"
                    horizontal
                  >
                    <b-input
                      class="giftInput"
                      name="huesped"
                      type="text"
                      v-model="reservation.guest"
                      v-validate="'required|min:2'"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </section>
            <!-- How you meet us -->
            <section class="card-meet-us" v-if="params.activeHowMeetUs == '1' && media.length > 0"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="custom-option-header">
                <span class="custom-option-title">{{ params.descriptionHowMeetUs !== '' ? params.descriptionHowMeetUs : $t('howFind')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !mediaType}"></i>
              </header>
              <div
                :message="errors.first('mediaType')"
                :type="{'is-danger': errors.has('mediaType')}"
                class="meet-us-list"
              >
                <b-radio
                  :key="client.id"
                  :native-value="client.name"
                  class="colorRadio"
                  name="mediaType"
                  type="is-precompro"
                  v-for="(client) in media"
                  v-model="mediaType"
                  v-validate="'required'"
                >{{client.name}}</b-radio>
              </div>
              <b-field
                :label="extraDescriptionMedia"
                :message="errors.first('extraDescriptionMedia')"
                :type="{'is-danger': errors.has('extraDescriptionMedia')}"
                class="description-media"
                v-if="mediaType != null && validateExtraDescription"
              >
                <b-input
                  class="giftInput"
                  name="extraDescriptionMedia"
                  type="text"
                  v-model="reservation.mediaDescription"
                  v-validate="'required|min:2'"
                ></b-input>
              </b-field>
            </section>
            <!-- Giftcard code -->
            <!-- v-if="reservation.isFree && params.activateGiftCard" -->
            <section class="card-giftcard-code" v-if="!hiddenGiftCard"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="custom-option-header">
                <i class="iconGift main-icon"></i>
                <span class="custom-option-title">{{$t('discountCode')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !codeGiftCard}"></i>
              </header>
              <p class="custom-option-text">{{$t('enterCodeGift')}}.</p>
              <b-field>
                <b-input
                  class="giftInput"
                  name="codeGiftCard"
                  type="text"
                  v-if="giftcardId == null"
                  v-model="codeGiftCard"
                ></b-input>
              </b-field>
              <h6
                class="l-item"
                v-if="giftcardId != null"
              >{{ priceGiftCard | currency('$ ', 0, { thousandsSeparator: ',' }) }}</h6>
              <div v-if="priceGiftCard === 0 && giftcardId === null" :style="`padding:3px 0 8px 0;font-weight: 500;${priceGiftCard === 0 && giftcardId === null ? 'color:red;': 'color:green;'}`">
                {{messageGiftCard}}
              </div>
              <div class="button-container">
                <b-button
                  :disabled="codeGiftCard == null"
                  @click="validateCode"
                  class="btn-code"
                  v-if="giftcardId == null"
                >{{$t('addCode')}}</b-button>
              </div>
            </section>
            <!-- Celebrations -->
            <section class="card-celebrations" v-if="params.activeCelebration"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="custom-option-header">
                <span class="custom-option-title" v-if="!isAndresDc()">{{$t('celebrateSomething')}}</span>
                <span class="custom-option-title" v-else>¿Estás celebrando alguna ocasión especial?</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !reservation.celebrationId}"></i>
              </header>
              <div class="celebration-list-plus-comments">
                <div class="celebrationList">
                  <div
                    :key="'celebration#' + cIn"
                    :class="`celebrationItem`"
                    v-for="(celebration, cIn) in celebrations"
                  >
                    <div class="container-icons" v-if="isIcon(celebration.imageURL)">
                      <i class="icon-mks celebrationImgRadio" :style="getIconStyle(celebration.imageURL)" ></i>
                    </div>
                    <img :src="celebration.imageURL" class="celebrationImgRadio" v-else height="35" width="35" />
                    <span class="celebrationText">{{ celebration.name }}</span>
                    <b-radio
                      :id="'celebrationRadio-'+celebration.id"
                      class="colorRadio cursor-pointer"
                      name="celebration"
                      v-model="celebrationRadio"
                      @input="setCelebration(celebration)"
                      type="is-precompro"
                    ></b-radio>
                  </div>
                  <div :class="`otherComment ${activeOptionsNumber == 2 ? '': ''}`" v-if="showCelebComment">
                    <b-field :label="$t('celebrationQuestion')">
                      <b-input type="text" v-model="reservation.celebrationComment" @change="updatePeople"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </section>
            <!-- Alergies -->
            <section class="card-alergies" v-if="params.isActiveAlergies"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <header class="">
                <i class="icon-mks icon_alergy"></i>
                <span class="">{{$t('comments_alergy')}}</span>
                <i class="icon-mks icon_check_rounded secondary-icon control-icon" :class="{inactive: !hasAlergy}"></i>
              </header>
              <div class="flex-alergies">
                <div class="block">
                  <b-radio class="colorRadio" type="is-precompro" v-model="hasAlergy" name="alergies" :native-value="true" @input="changeAlergy">
                    {{ $t('pollQrView.text4') }}
                  </b-radio>
                  <b-radio class="colorRadio" type="is-precompro" v-model="hasAlergy" name="alergies" :native-value="false" @input="changeAlergy">
                    {{ $t('pollQrView.text5') }}
                  </b-radio>
                </div>
                <b-field v-if="hasAlergy" class="alergy-comment">
                  <b-input type="text" v-model="reservation.alergies"></b-input>
                </b-field>
              </div>
            </section>
            <!-- Comments -->
            <section @click="focusTextarea" class="cursor-pointer card-comments" v-if="activeOptionsNumber >= 1 && vendor.id !== 60"
              :class="{'w-40': ((activeOptionsNumber === 2 || activeOptionsNumber >= 4) && params.showComment) || ((activeOptionsNumber === 3 || activeOptionsNumber >= 5) && !params.showComment), 'w-50': ((activeOptionsNumber === 1 || activeOptionsNumber === 3) && params.showComment) || ((activeOptionsNumber === 2 || activeOptionsNumber === 4) && !params.showComment)}"
            >
              <!-- <header class="custom-option-header">
                <span class="custom-option-title">{{$t('comments')}}:</span>
              </header> -->
              <b-field class="mw-100"
                :message="errors.first('comments')"
                :type="{'is-danger': errors.has('comments')}">
                <textarea ref="commentsTextarea" @click="handleTextareaClick" :class="computedClassTextArea" class="textarea" :placeholder="(!isAndresDc()) ? $t('comments')+':' : '¿Quieres dejarnos alguna observación especial para tu reserva?'" @change="validateInputComments" :maxlength="200" v-model="userData.comments"
                @blur="()=>{
                  updatePeople()
                  this.placeHolderActive = false
                }" v-autoresize></textarea>
                <small class="help counter" style="text-align: right;">{{userData.comments ? userData.comments.length : 0 }}/200</small>
                <p v-if="errors.has('comments')" class="help is-danger">{{ errors.first('comments') }}</p>
              </b-field>
            </section>
          </div>
        </b-collapse>

        <!-- Payment Summary -->
        <section class="checkout-main-section-content__pay-resume" v-if="reservation.isFree == false">
          <header class="" :style="[{ background: getDarkColor }]">
            <i class="icon-mks icon_money_checkout" style="margin-bottom: -4px;"></i>
            <p class="" v-if="reservation.isWarranty === 0"> {{ $t('paymentSummary') }} </p>
            <p class="" v-if="reservation.isWarranty === 1"> {{ $t('paymentSummaryW') }} </p>
          </header>
          <div class="checkout-main-section-content__pay-resume__container">
            <div
              class=""
              v-if="( !reservation.residuo && this.params.activeZoneCostPeople !== 1)"
            >
              <!--PRECOMPRA DESC-->
              <PaymentDescription :reservationInfo="reservation"></PaymentDescription>
              <!--EVENTO DESC-->
              <EventDescription :reservationInfo="reservation"></EventDescription>
              <!--POMBO DESC-->
              <PomboDescription :reservationInfo="reservation"></PomboDescription>
              <!--MAREA DESC-->
              <MareaDescription
                :payAdultMarea="costAdultMarea"
                :payBoyMarea="costBoyMarea"
                :reservationInfo="reservation"
              ></MareaDescription>
              <!--DECORACION DESC-->
              <DecorationDescription :decorationPay="decorationCost" :reservationInfo="reservation"></DecorationDescription>
              <!--Experience DESC-->
              <ExperienceDescription :experiencePay="experienceCost"></ExperienceDescription>
              <!--GAIRA DESC-->
              <GairaDescription :reservationDescription="reservation"></GairaDescription>
              <!--DECORATION TOTAL-->
              <DecorationTotal @showTerms="$emit('showTerms')" v-if="!(decorationCost>0 && experienceCost>0) && !(reservation.decoration && decorationCost ===0)" :decorationPay="decorationCost" :reservationInfo="reservation"></DecorationTotal>
              <!-- Suggested Tip -->
              <div class="suggest-tip" v-if="vendor.id == 310">
                <span class="">{{ $t('checkoutView.components.text3') }}</span>
                <span class="">
                  <b-radio
                    class=""
                    name="tenPercent"
                    native-value="10"
                    type="is-precompro"
                    v-model="tip3Cord"
                  >10 %</b-radio>
                  <b-radio
                    class=""
                    name="other"
                    native-value="other"
                    type="is-precompro"
                    v-model="tip3Cord"
                  >{{ $t('other') }}</b-radio>
                  <b-field v-if="tip3Cord === 'other'">
                    <b-numberinput :controls="false" min="0" v-model="tipCustom"></b-numberinput>
                  </b-field>
                </span>
              </div>
              <!--EXPERIENCE TOTAL-->
              <ExperienceTotal @showTerms="$emit('showTerms')" v-if="!(decorationCost>0 && experienceCost>0) || (!reservation.decoration && experienceCost>0 && experienceCost>0)" :experiencePay="experienceCost" :reservationInfo="reservation"></ExperienceTotal>
              <DecorationAndExperienceTotal @showTerms="$emit('showTerms')" v-else :reservationInfo="reservation" :decorationPay="reservation.decoration ? decorationCost : 0" :experiencePay="experienceCost"></DecorationAndExperienceTotal>
              <!--PRECOMPRA TOTAL-->
              <PaymentTotal
                :experienceCostT="experienceCost"
                :experiencePay="experienceCost"
                :payAdultMarea="costAdultMarea"
                :payBoyMarea="costBoyMarea"
                :reservationInfo="reservation"
                @showTerms="$emit('showTerms')"
              ></PaymentTotal>
              <!--MAREA PRECOMPRA TOTAL-->
              <MareaTotal
                :payAdultMarea="costAdultMarea"
                :payBoyMarea="costBoyMarea"
                :reservationInfo="reservation"
                @showTerms="$emit('showTerms')"
              ></MareaTotal>
              <!--EVENTO TOTAL-->
              <EventTotal :reservationInfo="reservation"></EventTotal>
              <!--GAIRA CENA RUMBA POMBO TOTAL-->
              <GairaTotal :reservationTotal="reservation" @showTerms="$emit('showTerms')"></GairaTotal>
              <!--GAIRA TEXTO POMBO TOTAL-->
              <GairaPomboTexto :reservationPomboTexto="reservation"></GairaPomboTexto>
            </div>

            <!-- la Jugada y la Movida -->
            <div class="list-total"
              v-if="((!reservation.residuo) && (this.params.activeZoneCostPeople == 1)) && reservation.typeReservation !== 'Pombo' && reservation.typeReservation !== 'Brunch' && reservation.typeReservation !== 'Rumba' && reservation.typeReservation !== 'Cena'"
            >
              <div class="flex-container" v-if="!reservation.costBoy">
                <p class="item-small-total">{{ reservation.balance / reservation.people | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.people }} {{$t('diners')}}</p>
                <p class="item-right-total">{{ reservation.balance | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <div class="flex-container" v-if="reservation.costBoy && reservation.adult > 0">
                <p class="item-small-total">{{ reservation.payDetails.costZone | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.adult }} {{$t('adults')}}</p>
                <p class="item-right-total">{{ reservation.payDetails.costZone * reservation.adult | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <div class="flex-container" v-if="reservation.costBoy && reservation.boy > 0">
                <p class="item-small-total">{{ reservation.payDetails.costBoy | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.boy }} {{$t('kids')}}</p>
                <p class="item-right-total">{{ reservation.payDetails.costBoy * reservation.boy | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <div class="total-container">
                <TooltipTYC @showTerms="$emit('showTerms')" />
                <p class="final-total-right">{{$t('total')}}:</p>
                <p class="final-total-right">{{ reservation.balance | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <hr />
              <div v-if="reservation.zone == 424 || reservation.zone == 415">
                <p class="text-tittle left-line">{{$t('details')}}</p>
                <div class="flex-container">
                  <p>{{$t('bottle4People')}} ({{$t('costMenu')}})</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('cover')}}</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('mixer')}}</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('services')}}</p>
                </div>
              </div>
              <div v-if="reservation.zone == 423 || reservation.zone == 417">
                <p class="text-tittle left-line">{{$t('details')}}</p>
                <div class="flex-container">
                  <p>{{$t('bottle4People')}} ({{$t('costMenu2')}})</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('cover')}}</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('mixer')}}</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('services')}}</p>
                </div>
              </div>
              <div v-if="reservation.zone == 425 || reservation.zone == 416">
                <p class="text-tittle left-line">{{$t('details')}}</p>
                <div class="flex-container">
                  <p>{{$t('bottle4People')}} ({{$t('costMenu3')}})</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('cover')}}</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('mixer')}}</p>
                </div>
                <div class="flex-container">
                  <p>{{$t('services')}}</p>
                </div>
              </div>
            </div>
            <div
              class="list-total"
              v-if=" (reservation.residuo && vendor.id == 150) || (reservation.residuo && vendor.id == 149) && reservation.typeReservation !== 'Pombo' && reservation.typeReservation !== 'Brunch' && reservation.typeReservation !== 'Rumba' && reservation.typeReservation !== 'Cena'"
            >
              <p class="text-tittle">{{$t('paidOut')}}</p>
              <div class="flex-container">
                <p>{{ reservation.paidOld /dataReservationEdit.people | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ dataReservationEdit.people }} {{$t('diners')}}</p>
                <p class="item-right-total">{{ reservation.paidOld | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <hr />
              <p class="text-tittle">{{$t('pendingPayment')}}</p>
              <div class="flex-container">
                <p>{{ reservation.balance / (reservation.people - dataReservationEdit.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.people - dataReservationEdit.people }} {{$t('diners')}}</p>
                <p class="item-right-total">{{ reservation.balance | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
            </div>
            <!--GAIRA TEXTO RUMBA CENA TOTAL-->
            <GairaGeneralTexto :reservationGeneralTexto="reservation"></GairaGeneralTexto>
            <!--EDICION CON PAGO-->
            <div class="list-total" v-if="reservation.residuo && vendor.id !== 150 && vendor.id !== 149">
              <p class="item-right-total">{{$t('paidOut')}}:</p>
              <div
                class="flex-container"
                v-if="!validateMareaSpecial() && !reservation.isEvent && (reservation.typeReservation !== 'Pombo' && reservation.typeReservation !== 'Brunch' && reservation.typeReservation !== 'Rumba' && reservation.typeReservation !== 'Cena')"
              >
                <p>{{ params.purchaseCost | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.dataOld.people - reservation.isEditWhiteLabel }} {{$t('diners')}}</p>
                <p class="item-right-total">{{ params.purchaseCost*(reservation.dataOld.people - reservation.isEditWhiteLabel) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <div
                class="flex-container"
                v-else-if="!validateMareaSpecial() && reservation.typeReservation === 'Pombo'"
              >
                <p>
                  {{ (reservation.payDetails.adult) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.dataOld.adult }} {{$t('adults')}}
                  <br />
                  {{ (reservation.payDetails.boy) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.dataOld.boy }} {{$t('kids')}}
                </p>
                <p class="item-right-total">
                  {{ ((reservation.payDetails.adult * reservation.dataOld.adult) + (reservation.payDetails.boy * reservation.dataOld.boy))
                  | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <div
                class="flex-container"
                v-else-if="!validateMareaSpecial() && reservation.typeReservation === 'Rumba' || reservation.typeReservation === 'Cena'"
              >
                <p>{{ (reservation.payDetails.adult) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.dataOld.people }} {{$t('diners')}}</p>
                <p class="item-right-total">{{ (reservation.payDetails.adult * reservation.dataOld.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <div class="flex-container"
                v-else-if="!validateMareaSpecial() && reservation.typeReservation !== 'Brunch'"
              >
                <p>{{ (reservation.balance / reservation.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.people }} {{$t('diners')}}</p>
                <p class="item-right-total">{{ reservation.balance | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <!--GAIRA COVER PAGADO DESC-->
              <div class="flex-container" v-if="reservation.payDetails" v-show="reservation.payDetails.cover > 0 ">
                <p>{{ (reservation.payDetails.cover) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('person')}}</p>
                <p class="item-right-total">{{ (reservation.payDetails.cover * reservation.dataOld.people) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <!--MAREA ADULTOS DESC-->
              <div class="flex-container" v-if="validateMareaSpecial()">
                <p>{{ (costAdultMarea) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.dataOld.adult }} {{$t('adults')}}</p>
                <p class="item-right-total">{{ (costAdultMarea * (reservation.dataOld.adult)) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <div class="flex-container" v-if="validateMareaSpecial() && reservation.dataOld.boy > 0">
                <p>{{ (costBoyMarea) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.dataOld.boy }} {{$t('kids')}}</p>
                <p class="item-right-total">{{ (costBoyMarea * (reservation.dataOld.boy)) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <!--PENDIENTE POR PAGAR DESC-->
              <hr />
              <p class="item-right-total">{{$t('pendingPayment')}}:</p>
              <div
                class="flex-container"
                v-if="!validateMareaSpecial() && !reservation.isEvent && (reservation.typeReservation !== 'Pombo' && reservation.typeReservation !== 'Brunch' && reservation.typeReservation !== 'Rumba' && reservation.typeReservation !== 'Cena')"
              >
                <p>
                  {{ params.purchaseCost | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ (reservation.people - reservation.dataOld.people) + reservation.isEditWhiteLabel }} {{$t('diners')}}
                </p>
                <p class="item-right-total">
                  {{ params.purchaseCost*(reservation.people - reservation.dataOld.people + reservation.isEditWhiteLabel) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP
                </p>
              </div>
              <!--POMBO-->
              <div
                class="flex-container"
                v-else-if="reservation.typeReservation === 'Pombo' && !validateMareaSpecial()"
              >
                <p>
                  {{ (reservation.payDetails.adult) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ (reservation.adult - reservation.dataOld.adult) }} {{$t('adults')}}
                  <br />
                  {{ (reservation.payDetails.boy) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ (reservation.boy - reservation.dataOld.boy) }} {{$t('kids')}}
                </p>
                <p class="item-right-total">
                  {{ ((reservation.payDetails.adult * (reservation.adult - reservation.dataOld.adult)) + (reservation.payDetails.boy * (reservation.boy - reservation.dataOld.boy))) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP
                </p>
              </div>
              <!--CENA - RUMBA-->
              <div
                class="flex-container"
                v-else-if="reservation.typeReservation === 'Rumba' || reservation.typeReservation === 'Cena'  && !validateMareaSpecial()"
              >
                <p>{{ (reservation.payDetails.adult) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ (reservation.people - reservation.dataOld.people) }} {{$t('diners')}}</p>
                <p
                  class="item-right-total"
                >{{ (reservation.payDetails.adult * (reservation.people - reservation.dataOld.people)) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <div
                class="flex-container"
                v-else-if="!validateMareaSpecial() && reservation.typeReservation !== 'Brunch'"
              >
                <p>{{ (reservation.balance / (reservation.people - reservation.dataOld.people)) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ (reservation.people - reservation.dataOld.people) }} {{$t('diners')}}</p>
                <p
                  class="item-right-total"
                >{{ reservation.balance | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <!--GAIRA COVER POR PAGAR DESC-->
              <div class="flex-container" v-if="reservation.payDetails" v-show="reservation.payDetails.cover > 0 ">
                <p>{{ (reservation.payDetails.cover) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{$t('person')}}</p>
                <p
                  class="item-right-total"
                >{{ (reservation.payDetails.cover * (reservation.people - reservation.dataOld.people)) | currency('$ ', 0, { thousandsSeparator: ',' }) }} COP</p>
              </div>
              <!--MAREA DESC-->
              <div class="flex-container" v-if="validateMareaSpecial()">
                <p>{{ (costAdultMarea) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.adult - reservation.dataOld.adult }} {{$t('adults')}}</p>
                <p
                  class="item-right-total"
                >{{ (costAdultMarea * (reservation.adult - reservation.dataOld.adult)) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
              <!--MAREA ADULTOS DESC-->
              <div
                class="flex-container"
                v-if="validateMareaSpecial() && (reservation.boy - reservation.dataOld.boy) > 0"
              >
                <p>{{ (costBoyMarea) | currency('$ ', 0, { thousandsSeparator: ',' }) }} * {{ reservation.boy - reservation.dataOld.boy }} {{$t('kids')}}</p>
                <p
                  class="item-right-total"
                >{{ (costBoyMarea * (reservation.boy - reservation.dataOld.boy)) | currency('$ ', 0, { thousandsSeparator: ',' }) }}</p>
              </div>
            </div>
            <!--TEXTOS -->
            <div
              v-if="reservation.isWarranty === 0 && reservation.typeReservation !== 'Rumba' && reservation.typeReservation !== 'Cena' && reservation.typeReservation !== 'Brunch' && reservation.typeReservation !== 'Pombo' && vendor.id !== 150 && vendor.id !== 149"
            >
              <span
                class="text-justify"
                v-if="reservation.isEvent && !reservation.reservationEvent && vendor.id !== 217 "
              >*{{params.consumableCopy}}</span>
              <span
                class="text-justify"
                v-if="reservation.isEvent && reservation.reservationEvent"
              >*{{$t('contributionArtist')}}</span>
              <span
                class="text-justify"
                v-if="reservation.decoration == false && !reservation.isEvent"
              >*{{params.consumableCopy}}</span>
              <span
                class="text-justify"
                v-if="reservation.decoration && reservation.balance - decorationCost > 0 && !reservation.reservationEvent"
              >*{{params.consumableCopy}}</span>
            </div>
            <div v-if="reservation.isWarranty === 1" class="warrantyText">
              <ul style="list-style: disc;">
                <li>Para garantizar tu reserva se realiza una autorización como garantía por persona.</li>
                <li>No se realizara un pago en este momento.</li>
                <li>Para verificar la tarjeta se generara un cobro de {{ reservation.warrantyPrice | currency("$ ", 0, { thousandsSeparator: "," }) }} que será devuelto inmediatamente.</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>

    <modalExperience
      :listExperience="listExperiencePerson"
      :reservation="reservation"
      @pay="experienceFunction"
      v-if="modalExp"
    />
    <b-modal :active.sync="showModalSubcribe" :can-cancel="false">
      <div class="modals-bosy">
        <h3 class="title-modal">{{$t('subscribe')}}</h3>
        <p class="general-subtitle">
          <b-field>
            <b-select
              @input="SelectOptionSubcribed"
              class="img-subs"
              expanded
              placeholder="Selecciona una Opción"
              v-model="subcribe_option"
            >
              <option>{{ $t('checkoutView.components.text4') }}</option>
              <option>{{ $t('checkoutView.components.text5') }}</option>
              <option>{{ $t('checkoutView.components.text6') }}</option>
              <option>{{ $t('checkoutView.components.text7') }}</option>
            </b-select>
          </b-field>
        </p>
        <div class="btns-modal" style="margin-top: 30px;">
          <button
            @click="RigthOptionSubbcribed"
            class="full-btn-modal btn-modal-fill"
            style="width:100%;"
            v-if="subcribe_option != null"
          >{{$t('agree')}}</button>
          <button
            @click="RigthOptionSubbcribed"
            class="full-btn-modal btn-modal-fill"
            style="width:100%;"
            v-if="subcribe_option == null"
          >{{$t('close')}}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import iconTime from '@/components/Icons/iconTime';
import authTypes from '@/store/types/auth';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import { mapGetters } from 'vuex';
import iconPeople from '@/components/Icons/iconPeople';
import iconReservation from '@/components/Icons/iconReservation';
import modalCompanion from '@/components/Checkout/modalCompanionNew';
import modalDecoration from '@/components/Checkout/modalDecoration';
import modalMainPlate from '@/components/Checkout/modalMainPlate';
import modalSureOptionReload from '@/components/Checkout/modalSureOptionReload';
import _ from 'lodash';
import moment from 'moment-timezone';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import modalExperience from './modalExperience.vue';
import GairaDescription from '@/components/Checkout/Gaira/Description';
import GairaTotal from '@/components/Checkout/Gaira/Total';
import GairaPomboTexto from '@/components/Checkout/Gaira/Texto/Pombo';
import GairaGeneralTexto from '@/components/Checkout/Gaira/Texto/General';
import PaymentDescription from '@/components/Checkout/Text/PaymentDescription';
import EventDescription from '@/components/Checkout/Text/EventDescription';
import PomboDescription from '@/components/Checkout/Text/PomboDescription';
import MareaDescription from '@/components/Checkout/Text/MareaDescription';
import DecorationDescription from '@/components/Checkout/Text/DecorationDescription';
import ExperienceDescription from '@/components/Checkout/Text/ExperienceDescription';
import PaymentTotal from '@/components/Checkout/Value/PaymentTotal';
import DecorationTotal from '@/components/Checkout/Value/DecorationTotal';
import ExperienceTotal from '@/components/Checkout/Value/ExperienceTotal';
import MareaTotal from '@/components/Checkout/Value/MareaTotal';
import DecorationAndExperienceTotal from '@/components/Checkout/Value/DecorationAndExperienceTotal';
import EventTotal from '@/components/Checkout/Value/EventTotal';
import serviceExperience from '@/helpers/apis/serviceExperience';
import serviceReservation from '@/helpers/apis/serviceReservation';
import InputC from '@/components/customInput.vue';
import inputSelector from '@/components/Select/inputs/inputSelectCheckout.vue';
import TooltipTYC from '@/components/Checkout/Value/TooltipTYC';

const datesInMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
export default {
  components: {
    iconTime,
    iconPeople,
    iconReservation,
    modalExperience,
    GairaDescription,
    GairaTotal,
    GairaPomboTexto,
    GairaGeneralTexto,
    PaymentDescription,
    EventDescription,
    PomboDescription,
    MareaDescription,
    DecorationDescription,
    ExperienceDescription,
    PaymentTotal,
    DecorationTotal,
    ExperienceTotal,
    MareaTotal,
    DecorationAndExperienceTotal,
    EventTotal,
    InputC,
    inputSelector,
    TooltipTYC
  },
  props: ['designProps'],
  data () {
    return {
      celebrationRadio: null,
      birthdayDate: '',
      backLightColor: {
        'background-color': this.designProps.bgColorCheckout,
        color: this.designProps.colorCheckout
      },
      secondColor: {
        'background-color': this.designProps.color
      },
      showGiftCardStatus: false,
      edit: false,
      codeGiftCard: null,
      disabledDecoration: false,
      celebrations: [],
      userData: {
        name: null,
        email: null,
        phone: null,
        comments: null,
        subscribed: null,
        address: null,
        identityDocument: '',
        giftcard: 0
      },
      colorCronometer: '#28a745',
      minutes: null,
      seconds: 59,
      index: 0,
      date: new Date(),
      interval: null,
      option: null,
      subcribe_option: null,
      indicativo: null,
      pais: 'Colombia',
      bandera: null,
      paises: [],
      clientTypes: [],
      client: [],
      media: [],
      showModalSubcribe: false,
      decoration: false,
      mainPlateState: false,
      listMainPlatePerson: [],
      listMainPlates: [],
      disabledMainPlate: false,
      experienceVendor: false,
      messageGiftCard: null,
      priceGiftCard: 0,
      giftcardId: null,
      birthDateMonth: null,
      birthDateDay: null,
      birthDate: null,
      nameConutry: '',
      isColombia: false,
      decorationId: null,
      decorationDescription: null,
      decorationMultiple: [],
      costAdultMarea: 0,
      costBoyMarea: 0,
      showCelebComment: 0,
      clientType: null,
      isLegalPerson: false,
      mediaType: null,
      isActiveHuesped: 0,
      extraDescription: null,
      extraDescriptionMedia: null,
      validateExtraDescription: false,
      validateTel: '',
      applyStrong: false,
      listExperiencePerson: [],
      tip3Cord: null,
      tipCustom: 0,
      timer: '00:00',
      identificationTypes: [{ name: 'C.C.', value: 'CC' }],
      activeOptionsNumber: null,
      hasAlergy: false,
      hasPets: false,
      countForShow: false,
      menu1: false,
      placeHolderActive: false,
      hiddenGiftCard: true,
      countryFlagsAvailable: false,
      validateDoc: '',
      countFlagValidate: 0,
      intervalAux: null,
      emailNotificactionCheck: false,
      smsNotificactionCheck: false,
      whatsappNotificactionCheck: false,
      allDecorationsAux: [],
      decorationsAux: null
    };
  },
  directives: {
    autoresize: {
      inserted: function (el) {
        el.style.height = 'auto';
        if (el.className.includes('text-area-diferent-height')) {
          el.style.setProperty('height', `${el.scrollHeight}px`, 'important');
        } else {
          el.style.setProperty('height', `${el.scrollHeight - 10}px`, 'important');
        }
        el.addEventListener('input', function () {
          if (el.scrollHeight >= 30) {
            el.style.height = 'auto';
            el.style.setProperty('height', `${el.scrollHeight}px`, 'important');
          }
        });
      }
    }
  },
  created () {
    this.$store.commit(reservationTypes.mutations.setReservationsUser);
  },
  computed: {
    ...mapGetters({
      userLogged: authTypes.getters.user,
      reservation: reservationTypes.getters.reservationsUser,
      reservationsExecuted: reservationTypes.getters.reservationsExecuted,
      decorationCost: reservationTypes.getters.decorationCosto,
      experienceCost: reservationTypes.getters.getExperienceCost,
      modalExp: whiteLabelTypes.getters.stateModalExperience,
      systemDate: globalTypes.getters.serverTime
    }),
    daysInMonth () {
      return datesInMonths[this.birthDateMonth - 1] || 31;
    },
    filteredDataObj () {
      return this.paises.filter((option) => {
        return option.label.toString().toLowerCase().indexOf(this.nameConutry.toLowerCase()) >= 0;
      });
    },
    uniqueExperiences () {
      if (!this.reservation.experiences) {
        return [];
      }
      const uniqueExp = {};
      this.reservation.experiences.forEach(e => {
        const exp = e.experience;
        const person = e.person;
        if (!uniqueExp[`${exp}_${person}`]) {
          uniqueExp[`${exp}_${person}`] = e;
        }
      });
      const result = Object.values(uniqueExp);
      return result;
    },
    getDarkColor () {
      if (this.design.bgBarTitle !== null && this.design.bgBarTitle !== '') {
        return this.colorWithTransparency(this.design.bgBarTitle, 0.3);
      } else {
        return this.colorWithTransparency(this.design.bgColorStepper, 0.3);
      }
    },
    computedClassTextArea () {
      return {
        'placeholder-active-text-area': this.placeHolderActive,
        'text-area-diferent-height': this.isAndresDc()
      };
    }
  },
  methods: {
    changeActivePet () {
      /* if (!this.hasPets) {
        this.reservation.pets = null;
      } */
    },
    changeAlergy () {
      if (!this.hasAlergy) {
        this.reservation.alergies = null;
      }
    },
    getActiveOptionsNumber () {
      let counter = 0;
      if (this.applyStrong && this.params.activeMainPlate) {
        counter++;
      }
      if (this.params.decorationActive) {
        counter++;
      }
      if (this.listExperiencePerson.length > 0 && this.params.activeExperiencies && this.reValidateShowExperience()) {
        counter++;
      }
      if (this.params.activeSelectorClient === 1 && this.clientTypes.length > 0) {
        counter++;
      }
      if (this.params.activeHowMeetUs === 1 && this.media.length > 0) {
        counter++;
      }
      /* if (this.reservation.isFree && this.params.activateGiftCard) {
        counter++;
      } */
      if (this.params.activeCelebration) {
        counter++;
      }
      if (this.params.activateAdditionalQuestions) {
        counter++;
      }
      if (this.params.isActiveAlergies) {
        counter++;
      }
      if (this.params.isActivePets) {
        counter++;
      }
      /* if (this.params.activateGiftCard) {
        counter--;
      } */
      this.activeOptionsNumber = counter;
    },
    isIcon (url) {
      if (url) {
        const extension = url.split('.').pop().toLowerCase();
        return extension === 'svg';
      } else {
        return '';
      }
    },
    getIconStyle (url) {
      return {
        backgroundColor: this.design.colorCheckout,
        WebkitMaskImage: `url(${url})`,
        maskImage: `url(${url})`
      };
    },
    changedValuePhone (values) {
      const { countrieInfo, inputValue } = values;
      this.validatePhoneRegex(inputValue, countrieInfo.indicativo);
      this.countFlagValidate++;
      if (this.countFlagValidate === 0 || this.countFlagValidate > 3) {
        this.bandera = countrieInfo.bandera;
        this.pais = countrieInfo.pais;
        this.userData.phone = inputValue;
        this.indicativo = countrieInfo.indicativo;
        this.updatePeople();
      }
    },
    changedValueIdentity (values) {
      this.userData.identityDocument = values.inputValue;
    },
    getShowComment (id) {
      if (id === 16 || id === 578 || id === 715 || id === 712) {
        return true;
      } else {
        return false;
      }
    },
    validateInputComments (textarea) {
      // Obtener el valor actual del textarea
      const inputValue = textarea.target.value;
      // Validar que el valor no contenga emojis
      const regex = /[\uD800-\uDFFF]/g; // Expresión regular para detectar emojis
      if (regex.test(inputValue)) {
        // Si se encuentra un emoji, eliminarlo del valor del textarea
        const newValue = inputValue.replace(regex, '');
        textarea.target.value = newValue;
        this.userData.comments = newValue;
      } else {
        this.userData.comments = textarea.target.value;
      }
      this.updatePeople();
    },
    indicativeParam (indicativo) {
      const selectCountry = this.paises.find(value => value.value === indicativo);
      this.option = selectCountry;
      this.changedPaisSelectedReservation(selectCountry);
    },
    dayOfWeekReservation (date) {
      return this.$moment(date).format('d');
    },
    validateActiveSelectorBoys (type = null) {
      if (type != null) {
        if ((this.reservation.typeReservation === 'Pombo' || this.vendor.id === 118 || this.vendor.id === 231) && (this.params.activeSelectorBoy === 1)) {
          return true;
        }
        return false;
      }
      if ((this.reservation.typeReservation === 'Pombo' || this.vendor.id === 118 || this.vendor.id === 231) && (this.params.activeSelectorBoy === 1 || this.params.activeSelectorBaby === 1)) {
        return true;
      }
    },
    validateActiveSelectorPeople () {
      if (this.params.activeSelectorBaby === 0 && this.params.activeSelectorBoy === 0 && this.vendor.id === 231) {
        return true;
      }
    },
    listClientType () {
      this.$http.get('/admin/origin/clientTypes/' + this.vendor.id).then(({ data }) => {
        this.clientTypes = data;
        this.clientTypes.forEach(client => {
          if (client.type === 'client') {
            this.client.push(client);
          } else {
            this.media.push(client);
          }
        });
        this.getActiveOptionsNumber();
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error en la solicitud',
          type: 'is-danger'
        });
      });
    },
    setCelebration (val) {
      this.celebrationRadio = val.id;
      if (val.comments) {
        this.showCelebComment = 1;
      } else {
        this.showCelebComment = 0;
      }
      this.reservation.celebrationId = val.id;
      window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
    },
    getCelebrations () {
      this.$http.get('/reservation/getCelebrations/' + this.vendor.id).then(({ data }) => {
        this.celebrations = data.data;
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: 'Error en la solicitud',
          type: 'is-danger'
        });
      });
    },
    validateMareaSpecial () {
      if (this.vendor.id === 231 && (this.costAdultMarea > 0 || this.costBoyMarea > 0)) {
        return true;
      } else {
        return false;
      }
    },
    getPayReservation () {
      let data = false;
      let info = window.localStorage.getItem('payReservations');
      if (info !== undefined && info != null) {
        data = true;
      }
      return data;
    },
    getPayReservationValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservations');
      if (info !== undefined && info != null) {
        data = parseInt(info);
      }
      return data;
    },
    getPayReservationBoy () {
      let data = false;
      let info = window.localStorage.getItem('payReservationBoys');
      if (info !== undefined && info != null) {
        data = true;
      }
      return data;
    },
    getPayReservationBoyValue () {
      let data = 0;
      let info = window.localStorage.getItem('payReservationBoys');
      if (info !== undefined && info != null) {
        data = parseInt(info);
      }
      return data;
    },
    getExperiencesValue () {
      let value = 0;
      if ((this.experienceCost !== undefined || this.experienceCost !== null)) {
        value = parseInt(this.experienceCost);
      }
      return value;
    },
    getMonthName (indx) {
      return months[indx];
    },
    selectFirst () {
      this.nameConutry = '';
      this.bandera = null;
    },
    isContry () {
      var isc = this.paises.filter((option) => {
        if (option.label === this.nameConutry) {
          return option;
        }
      });
      if (isc.length <= 0) {
        this.nameConutry = '';
      }
    },
    listPaisesReservation () {
      this.paises = this.listCountry();
      this.paises.unshift({
        value: 57,
        name: 'Colombia',
        label: 'Colombia ( +57 )',
        selected: true
      });
    },
    changedPaisReservation (indicativo, country = null, bandera = null) {
      let data;
      if (country === '') {
        bandera = this.bandera;
      }
      if (country) {
        data = _(this.paises)
          .filter(pais => {
            if (pais.label.includes(country)) {
              return pais;
            }
          })
          .value();
      } else {
        data = _(this.paises)
          .filter(pais => {
            if (parseInt(pais.value) === parseInt(indicativo)) {
              return pais;
            }
          })
          .value();
      }
      if (data.length > 0) {
        data[0].selected = true;
        this.option = data[0];
        this.nameConutry = data[0].label;
        let aux2 = null;
        let aux3 = data[0].label;
        this.pais = data[0].name;
        this.indicativo = data[0].value;
        let ind = data[0].value;
        _.forEach(BanderaPais, function (item) {
          let data2 = item.country + ' ( +' + ind + ' )';
          if (data2 === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        });
        if (bandera) {
          this.bandera = bandera;
        } else {
          this.bandera = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
        }
        this.updatePeople();
      }
    },
    validateTelIndicative (indicative) {
      const paisId = (indicative === null) ? 57 : parseInt(indicative);
      this.validateTel = '';
      switch (paisId) {
        case 57:
          this.validateTel = 'required|min:10|max:10|numeric';
          break;
        case 507:
          this.validateTel = 'required|min:7|max:8|numeric';
          break;
        default:
          this.validateTel = 'required|min:7|max:11|numeric';
          break;
      }
    },
    changedPaisSelectedReservation (indicativo) {
      if (indicativo !== undefined) {
        let aux2 = null;
        let aux3 = indicativo.label;
        this.pais = indicativo.name;
        this.nameConutry = indicativo.label;
        this.indicativo = indicativo.value;
        let ind = indicativo.value;
        _.forEach(BanderaPais, function (item) {
          let data2 = item.country + ' ( +' + ind + ' )';
          if (data2 === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        });
        this.bandera = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
        indicativo.selected = true;
        this.option = indicativo;
        /* this.validateTelIndicative(indicativo.value); */
        let dataCopy = { ...this.userData };
        dataCopy.phone = this.userData.phone + ' ';
        this.userData = dataCopy;
        setTimeout(() => {
          dataCopy.phone = this.userData.phone.trim();
          this.userData = dataCopy;
        }, 100);
        this.validateIndicativeColombia(this.indicativo);
        this.updatePeople();
      }
    },
    updatePeople () {
      this.reservation.name = this.userData.name;
      this.reservation.displayName = this.userData.name;
      this.reservation.phone = this.userData.phone;
      this.reservation.email = this.userData.email;
      this.reservation.indicativo = this.indicativo;
      this.reservation.bandera = this.bandera;
      this.reservation.country = this.pais;
      this.reservation.comments = this.userData.comments;
      this.reservation.subscribed = this.userData.subscribed;
      this.reservation.identityDocument = this.userData.identityDocument;
      this.reservation.address = this.userData.address;
      this.user.identityDocument = this.userData.identityDocument;
      this.user.phone = this.userData.phone;
      this.user.bandera = this.bandera;
      this.user.indicativo = this.indicativo;
      window.localStorage.setItem('_user', btoa(JSON.stringify(this.user)));
      this.$store.commit(authTypes.mutations.setUser);
      window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
      this.edit = false;
      this.validateIndicativeColombia(this.reservation.indicativo);
    },
    SelectOptionSubcribed () {
      if (this.subcribe_option != null) {
        this.reservation.subscribedTo = this.subcribe_option;
      }
    },
    RigthOptionSubbcribed () {
      this.showModalSubcribe = false;
      if (this.subcribe_option == null) {
        this.userData.subscribed = null;
        this.updatePeople();
      }
    },
    closeGiftCard () {
      if (this.priceGiftCard > 0) {
        this.reservation.giftcardId = this.giftcardId;
        this.reservation.giftcardPrice = this.priceGiftCard;
        window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
        this.$store.commit(reservationTypes.mutations.setReservationsUser);
      }
      this.codeGiftCard = null;
      this.showGiftCardStatus = false;
    },
    openModalSubcribe () {
      this.showModalSubcribe = true;
      if (this.userData.subscribed == null) {
        this.subcribe_option = null;
      }
    },
    reloadWithoutStatus () {
      this.$store.commit(reservationTypes.mutations.setSendReservation, false);
      if (!this.reservationsExecuted) {
        this.$emit('timeOut', true);
        window.localStorage.removeItem('_reservation');
        window.localStorage.removeItem('zoneId');
        window.localStorage.removeItem('_oldPayExperience');
        window.localStorage.removeItem('_oldPayExperienceFull');
        window.localStorage.removeItem('_reservation_time');
        window.localStorage.removeItem('alert_timing');
      } else {
        window.location.href = '/confirmation';
      }
    },
    cronometer () {
      let moment = this.$moment(this.systemDate);
      this.$store.dispatch({
        type: 'global:getServerTime',
        data: {
          timezone: this.vendor.timezone
        }
      }).then(({ serverTime }) => {
        moment = serverTime;
      });
      // Obtener el valor del almacenamiento local
      const moreTime = window.localStorage.getItem('_reservation_more_time');
      const encodedValue = window.localStorage.getItem('_reservation_time');
      const moreTimeDecoded = atob(moreTime);
      if (encodedValue) {
        try {
          // Decodificar el valor en base64
          const decodedValue = atob(encodedValue);
          // Crear una instancia de momento con el valor decodificado
          let momentInstance = this.$moment(decodedValue);
          // Validar si es una fecha válida
          const isValidDate = momentInstance.isValid();
          if (isValidDate) {
            let plusminutes;
            if (moreTimeDecoded && parseInt(moreTimeDecoded) === 1) {
              plusminutes = momentInstance.add(2, 'minutes');
            } else {
              if (this.reservation.balance <= 0) {
                plusminutes = momentInstance.add(3, 'minutes');
              } else {
                plusminutes = momentInstance.add(9, 'minutes');
              }
            }
            if (plusminutes) {
              let seconds = plusminutes.diff(moment, 'seconds');
              let minutes = Math.floor(seconds / 60); // obtiene los minutos
              let remainingSeconds = seconds % 60; // obtiene los segundos restantes
              if (isNaN(minutes) && isNaN(remainingSeconds)) {
                this.timer = '00:00';
              } else {
                let timer = minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
                this.timer = timer;
              }
              if (moreTimeDecoded && parseInt(moreTimeDecoded) === 1) {
                this.colorCronometer = '#f44336';
              } else {
                if (seconds < 60 && this.reservation.isFree) {
                  this.colorCronometer = '#f44336';
                }
                if (seconds < 360 && !this.reservation.isFree) {
                  this.colorCronometer = '#f44336';
                }
              }
              if (seconds <= 0) {
                this.timer = '00:00';
                this.reloadWithoutStatus();
              }
              if (seconds <= 30) {
                if (window.localStorage.getItem('alert_timing') === undefined || window.localStorage.getItem('alert_timing') === null) {
                  if (moreTimeDecoded && isNaN(parseInt(moreTimeDecoded))) {
                    const instance = this;
                    this.$buefy.modal.open({
                      parent: this,
                      component: modalSureOptionReload,
                      props: {
                        timeLaps: seconds
                      },
                      events: {
                        addMoreTime () {
                          serviceReservation
                            .post('/reservation/extend/time', {
                              futureReservationId: instance.reservation.futureReservationsId
                            })
                            .then(({ data }) => {
                              if (!data.reservationTime) {
                                instance.$buefy.toast.open({
                                  message: 'Error en la solicitud',
                                  type: 'is-danger'
                                });
                              } else {
                                window.localStorage.setItem(
                                  '_reservation_more_time',
                                  btoa(1)
                                );
                                setTimeout(() => {
                                  instance.$store.dispatch({
                                    type: 'global:getServerTime',
                                    data: {
                                      timezone: instance.vendor.timezone
                                    }
                                  }).then(({ serverTime }) => {
                                    window.localStorage.setItem(
                                      '_reservation_time',
                                      btoa(serverTime)
                                    );
                                    instance.getDateForCronometer();
                                    instance.interval = window.setInterval(instance.cronometer, 1000); // Frecuencia de actualización
                                  });
                                }, 500);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                              instance.$buefy.toast.open({
                                message: 'Error en la solicitud',
                                type: 'is-danger'
                              });
                            });
                        }
                      },
                      hasModalCard: true,
                      customClass: 'modal-sure-reload-reservation',
                      trapFocus: true,
                      canCancel: false
                    });
                  }
                }
              }
            }
          } else {
            console.error('Error decodificando la fecha: ', decodedValue);
            this.timer = '00:00';
            clearInterval(this.interval);
            this.$store.commit(reservationTypes.mutations.setSendReservation, false);
            this.$emit('timeOut', true);
            window.localStorage.removeItem('_reservation');
            window.localStorage.removeItem('zoneId');
            window.localStorage.removeItem('_oldPayExperience');
            window.localStorage.removeItem('_oldPayExperienceFull');
            window.localStorage.removeItem('_reservation_time');
            window.localStorage.removeItem('_reservation_more_time');
            window.localStorage.removeItem('alert_timing');
          }
        } catch (error) {
          console.error('Error decodificando la fecha:', error);
          this.timer = '00:00';
          clearInterval(this.interval);
          this.$store.commit(reservationTypes.mutations.setSendReservation, false);
          this.$emit('timeOut', true);
          window.localStorage.removeItem('_reservation');
          window.localStorage.removeItem('zoneId');
          window.localStorage.removeItem('_oldPayExperience');
          window.localStorage.removeItem('_oldPayExperienceFull');
          window.localStorage.removeItem('_reservation_time');
          window.localStorage.removeItem('_reservation_more_time');
          window.localStorage.removeItem('alert_timing');
        }
      } else {
        console.warn('No se encontró ningún valor en localStorage para la clave _reservation_time');
        this.timer = '00:00';
        clearInterval(this.interval);
        this.$store.commit(reservationTypes.mutations.setSendReservation, false);
        this.$emit('timeOut', true);
        window.localStorage.removeItem('_reservation');
        window.localStorage.removeItem('zoneId');
        window.localStorage.removeItem('_oldPayExperience');
        window.localStorage.removeItem('_oldPayExperienceFull');
        window.localStorage.removeItem('_reservation_time');
        window.localStorage.removeItem('_reservation_more_time');
        window.localStorage.removeItem('alert_timing');
      }
    },
    validateCode () {
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      this.$store.dispatch({ type: 'reservation:validateGiftCard', data: { vendorId: this.vendor.id, codeGiftCard: this.codeGiftCard } })
        .then(response => {
          if (response.code === 200) {
            if (response.status) {
              this.messageGiftCard = 'Tienes un saldo a favor de ';
              this.priceGiftCard = response.value;
              this.giftcardId = response.id;
            } else {
              this.messageGiftCard = 'El código ya fue utilizado';
              this.priceGiftCard = 0;
              this.giftcardId = null;
            }
          } else {
            this.messageGiftCard = '*Tu código en invalido, vuelve a intentarlo';
            this.priceGiftCard = 0;
            this.giftcardId = null;
          }
          this.showGiftCardStatus = true;
        })
        .finally(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        });
    },
    validateForm () {
      let instance = this;
      this.$validator.validateAll().then(() => {
        if (this.errors.first('country')) {
          instance.$emit('showPhone', true);
        } else {
          instance.$emit('showPhone', false);
        }
      });
    },
    generateBirthDate (test) {
      const birthDateFormat = this.$moment(this.birthDate).format('YYYY-MM-DD');
      this.$emit('birthDay', birthDateFormat);
    },
    validateIndicativeColombia (indicativo) {
      if (indicativo === '54' || indicativo === 54) {
        this.isColombia = false;
      } else {
        this.isColombia = true;
      }
    },
    modalCompanion () {
      const instance = this;
      this.$buefy.modal.open({
        parent: this,
        component: modalCompanion,
        props: {
          infoReserva: instance.reservation
        },
        events: {
          updateCompanion (data) {
            instance.reservation.companions = data;
            window.localStorage.setItem('_reservation', btoa(JSON.stringify(instance.reservation)));
            instance.$store.commit(reservationTypes.mutations.setReservationsUser);
          }
        },
        hasModalCard: true,
        customClass: 'modal-companion-redesign',
        trapFocus: true,
        canCancel: false
      });
    },
    modalDecoration () {
      if (this.decoration) {
        var instance = this;
        this.$buefy.modal.open({
          props: {
            decorationId: instance.reservation.decorationId
          },
          parent: instance,
          events: {
            setId ($event) {
              instance.decorationId = $event.id;
              instance.decorationDescription = $event.name;
            },
            setMultiple ($event) {
              instance.decorationMultiple = $event;
            },
            deco ($event) {
              if ($event > 0) {
                instance.decorationFunction();
              } else {
                instance.experienceVendorFunction();
              }
            },
            close () {
              instance.decoration = false;
            }
          },
          component: modalDecoration,
          canCancel: false,
          customClass: 'main_modal_decoration'
        });
      } else {
        if (this.decorationCost > 0) {
          this.decorationFunction();
        } else {
          this.experienceVendorFunction();
        }
      }
    },
    getListMainPlate () {
      this.loading = true;
      this.$http.get(`admin/plates/restaurant/${this.vendor.id}`).then(({ data }) => {
        this.listMainPlates = data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    modalShowMainPlate () {
      if (this.mainPlateState) {
        this.$emit('changeCheckedPlate', true);
        var instance = this;
        this.$buefy.modal.open({
          parent: instance,
          width: 614,
          customClass: 'modal-size-plate',
          props: {
            reservation: this.reservation,
            listPlates: this.listMainPlates
          },
          events: {
            close () {
              instance.$emit('changeCheckedPlate', false);
              instance.mainPlateState = false;
            }
          },
          component: modalMainPlate,
          canCancel: false
        });
      }
    },
    reValidateShowExperience () {
      if (this.reservation.isEvent) {
        return false;
      }
      return true;
    },
    getListExperience () {
      this.loading = true;
      serviceExperience.get(`experience/${this.vendor.id}`).then(({ data }) => {
        this.listExperiencePerson = data.data;
        this.loading = false;
        this.filterByDayExperience();
      }).catch(() => {
        this.loading = false;
      });
    },
    filterByDayExperience () {
      const todayDate = (parseInt(parseInt(this.reservation.dateTime) - this.$moment().seconds(0).milliseconds(0).format('x')) / 3600000);
      const dayOfWeek = this.$moment(this.reservation.date, 'YYYY-MM-DD').day();
      const sectionId = (this.selectedZone !== false) ? this.selectedZone : window.localStorage.getItem('zoneId');
      const newList = [];
      this.listExperiencePerson.forEach((experience) => {
        let next = false;
        if (experience.anticipation) {
          if (todayDate >= experience.anticipation) {
            next = true;
          } else if (experience.anticipation === 0) {
            next = true;
          }
        } else {
          next = true;
        }
        if (next) {
          // Solo dia de la semana
          if (
            (experience.dayOfWeek.includes(dayOfWeek) || experience.dates.includes(this.reservation.date)) &&
            !experience.perZone &&
            !experience.perSubZone &&
            experience.typeLimit == null
          ) {
            newList.push(experience);
            newList.sort((a, b) => a.order - b.order);
          }
          // dia de la semana + zona
          if (
            (experience.dayOfWeek.includes(dayOfWeek) || experience.dates.includes(this.reservation.date)) &&
            experience.perZone &&
            !experience.perSubZone &&
            experience.typeLimit == null
          ) {
            if (sectionId !== false && sectionId !== undefined) {
              if (experience.perZone && experience.seatingSectionId.includes(parseInt(sectionId))) {
                newList.push(experience);
                newList.sort((a, b) => a.order - b.order);
              }
            }
          }
          // dia de la semana + subzona
          if (
            (experience.dayOfWeek.includes(dayOfWeek) || experience.dates.includes(this.reservation.date)) &&
            !experience.perZone &&
            experience.perSubZone &&
            experience.typeLimit == null
          ) {
            if (sectionId !== false && sectionId !== undefined) {
              if (experience.perSubZone && experience.seatingSubSectionId.includes(parseInt(sectionId))) {
                newList.push(experience);
                newList.sort((a, b) => a.order - b.order);
              }
            }
          }
          // dia de la semana + limite + zona
          if (
            (experience.dayOfWeek.includes(dayOfWeek) || experience.dates.includes(this.reservation.date)) &&
            experience.perZone &&
            !experience.perSubZone &&
            experience.typeLimit != null
          ) {
            if (sectionId !== false && sectionId !== undefined) {
              if (experience.perZone && experience.seatingSectionId.includes(parseInt(sectionId))) {
                if (experience.typeLimit === 'people') {
                  serviceExperience.get('/experience/' + this.vendor.id + '/' + experience._id + '/people?date=' + this.reservation.date).then(({ data }) => {
                    const maxi = parseInt(data.data) + this.reservation.people;
                    if (maxi <= experience.limit) {
                      newList.push(experience);
                      newList.sort((a, b) => a.order - b.order);
                    }
                  });
                }
                if (experience.typeLimit === 'reservation') {
                  serviceExperience.get('/experience/' + this.vendor.id + '/' + experience._id + '/reservation?date=' + this.reservation.date).then(({ data }) => {
                    const maxi = parseInt(data.data) + 1;
                    if (maxi <= experience.limit) {
                      newList.push(experience);
                      newList.sort((a, b) => a.order - b.order);
                    }
                  });
                }
              }
            }
          }
          // dia de la semana + limite + subzona
          if (
            (experience.dayOfWeek.includes(dayOfWeek) || experience.dates.includes(this.reservation.date)) &&
            !experience.perZone &&
            experience.perSubZone &&
            experience.typeLimit != null
          ) {
            if (sectionId !== false && sectionId !== undefined) {
              if (experience.perSubZone && experience.seatingSubSectionId.includes(parseInt(sectionId))) {
                if (experience.typeLimit === 'people') {
                  serviceExperience.get('/experience/' + this.vendor.id + '/' + experience._id + '/people?date=' + this.reservation.date).then(({ data }) => {
                    const maxi = parseInt(data.data) + this.reservation.people;
                    if (maxi <= experience.limit) {
                      newList.push(experience);
                      newList.sort((a, b) => a.order - b.order);
                    }
                  });
                }
                if (experience.typeLimit === 'reservation') {
                  serviceExperience.get('/experience/' + this.vendor.id + '/' + experience._id + '/reservation?date=' + this.reservation.date).then(({ data }) => {
                    const maxi = parseInt(data.data) + 1;
                    if (maxi <= experience.limit) {
                      newList.push(experience);
                      newList.sort((a, b) => a.order - b.order);
                    }
                  });
                }
              }
            }
          }
          // dia de la semana + limite
          if (
            (experience.dayOfWeek.includes(dayOfWeek) || experience.dates.includes(this.reservation.date)) &&
            !experience.perZone &&
            !experience.perSubZone &&
            experience.typeLimit != null
          ) {
            if (experience.typeLimit === 'people') {
              serviceExperience.get('/experience/' + this.vendor.id + '/' + experience._id + '/people?date=' + this.reservation.date).then(({ data }) => {
                const maxi = parseInt(data.data) + this.reservation.people;
                if (maxi <= experience.limit) {
                  newList.push(experience);
                  newList.sort((a, b) => a.order - b.order);
                }
              });
            }
            if (experience.typeLimit === 'reservation') {
              serviceExperience.get('/experience/' + this.vendor.id + '/' + experience._id + '/reservation?date=' + this.reservation.date).then(({ data }) => {
                const maxi = parseInt(data.data) + 1;
                if (maxi <= experience.limit) {
                  newList.push(experience);
                  newList.sort((a, b) => a.order - b.order);
                }
              });
            }
          }
        }
      });
      this.listExperiencePerson = newList;
      this.$emit('exeperienceBlocked', (newList.length > 0));
      let show = true;
      let trans = this.$route.query;
      if (Object.keys(trans).length > 0) {
        if (trans.source_utm) {
          show = false;
        }
      }
      if (Object.keys(this.listExperiencePerson).length > 0) {
        window.localStorage.removeItem('_invalid_alert_experience');
        if (this.params.activeExperiencies === 1) {
          show = this.reValidateShowExperience();
          this.modalShowExperience(show);
        }
      }
      this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
    },
    modalShowExperience (show) {
      const verifyingPayment = this.$store.getters['global:verifyingPayment'];
      if (show && !verifyingPayment) {
        this.$store.commit(whiteLabelTypes.mutations.setModalExperience, true);
      }
    },
    searchPlateName (id) {
      let obj;
      obj = this.listMainPlates.find(x => x.id === id);
      return obj.name;
    },
    decorationFunction () {
      if (!this.disabledDecoration) {
        if (this.decoration) {
          if (this.decorationMultiple.length > 0) {
            this.reservation.decorations = this.decorationMultiple;
          }
          this.reservation.decorationId = this.decorationId;
          if (this.reservation.isFree) {
            this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.decorationCost);
            this.reservation.totalBalance = parseInt(this.reservation.balance);
            this.reservation.isFree = false;
          } else {
            if (this.reservation.people >= this.params.minimumChargedPeople) {
              let debe = parseInt(this.reservation.people) * parseInt(this.params.purchaseCost);
              if (this.reservation.balance === debe) {
                this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.decorationCost);
                if (this.reservation.residuo === undefined) {
                  this.reservation.totalBalance = parseInt(this.reservation.balance);
                } else {
                  this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.decorationCost);
                }
              } else {
                if (this.reservation.residuo !== undefined) {
                  this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.decorationCost);
                  this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.decorationCost);
                }
              }
            } else {
              this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.decorationCost);
              if (this.reservation.residuo === undefined) {
                this.reservation.totalBalance = parseInt(this.reservation.balance);
              } else {
                this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.decorationCost);
              }
            }
          }
          this.minutes = 9;
          this.reservation.decoration = true;
          this.reservation.giftcardId = null;
          this.reservation.giftcardPrice = 0;
          this.giftcardId = null;
          this.priceGiftCard = null;
          window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
          this.$store.commit(reservationTypes.mutations.setReservationsUser);
        } else {
          if (this.reservation.isFree === false) {
            if (this.params.paymentTypeActive === 1 && this.params.paymentTypePSEActive === 1) {
              this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
            }
          }
          this.reservation.decorations = [];
          this.reservation.decorationId = null;
          this.decorationDescription = null;
          this.reservation.balance = parseInt(this.reservation.balance) - parseInt(this.decorationCost);
          if (this.reservation.residuo === undefined) {
            this.reservation.totalBalance = parseInt(this.reservation.balance);
          } else {
            this.reservation.totalBalance = parseInt(this.reservation.totalBalance) - parseInt(this.decorationCost);
          }
          if (this.reservation.balance > 0) {
            this.reservation.decoration = false;
          } else {
            this.reservation.decoration = false;
            this.reservation.isFree = true;
            this.minutes = 2;
          }
          window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
          this.$store.commit(reservationTypes.mutations.setReservationsUser);
        }
      }
    },
    experienceFunction () {
      const pay = window.localStorage.getItem('_oldPayExperience');
      const payFull = window.localStorage.getItem('_oldPayExperienceFull');
      if (this.experienceCost > 0 && this.params.activeExperiencies) {
        if (this.reservation.isFree) {
          this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.experienceCost);
          this.reservation.totalBalance = parseInt(this.reservation.balance);
          this.reservation.isFree = false;
        } else {
          if (this.reservation.people >= this.params.minimumChargedPeople) {
            let debe = parseInt(this.reservation.people) * parseInt(this.params.purchaseCost);
            if (this.reservation.balance === debe) {
              this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.experienceCost);
              if (this.reservation.residuo === undefined) {
                this.reservation.totalBalance = parseInt(this.reservation.balance);
              } else {
                this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.experienceCost);
              }
            } else {
              if (window.localStorage.getItem('_oldPayExperience')) {
                this.reservation.balance = ((pay !== undefined) ? parseInt(pay) : 0) + parseInt(this.experienceCost);
                if (this.reservation.residuo === undefined) {
                  this.reservation.totalBalance = parseInt(this.reservation.balance);
                } else {
                  this.reservation.totalBalance = (payFull !== undefined) ? parseInt(payFull) : 0;
                }
              } else {
                this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.experienceCost);
                if (this.reservation.residuo === undefined) {
                  this.reservation.totalBalance = parseInt(this.reservation.balance);
                } else {
                  this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.experienceCost);
                }
              }
            }
          } else {
            if (window.localStorage.getItem('_oldPayExperience')) {
              this.reservation.balance = ((pay !== undefined) ? parseInt(pay) : 0) + parseInt(this.experienceCost);
              if (this.reservation.residuo === undefined) {
                this.reservation.totalBalance = parseInt(this.reservation.balance);
              } else {
                this.reservation.totalBalance = (payFull !== undefined) ? parseInt(payFull) : 0;
              }
            } else {
              this.reservation.balance = parseInt(this.reservation.balance) + parseInt(this.experienceCost);
              if (this.reservation.residuo === undefined) {
                this.reservation.totalBalance = parseInt(this.reservation.balance);
              } else {
                this.reservation.totalBalance = parseInt(this.reservation.totalBalance) + parseInt(this.experienceCost);
              }
            }
          }
        }
        this.minutes = 9;
        this.reservation.giftcardId = null;
        this.reservation.giftcardPrice = 0;
        this.giftcardId = null;
        this.priceGiftCard = null;
        window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
        this.$store.commit(reservationTypes.mutations.setReservationsUser);
      } else {
        if (this.reservation.isFree === false) {
          if (this.params.paymentTypeActive === 1 && this.params.paymentTypePSEActive === 1) {
            this.$store.commit(whiteLabelTypes.mutations.setPaymentType, false);
          }
        }
        this.reservation.balance = parseInt(window.localStorage.getItem('_oldPayExperience'));
        if (this.reservation.residuo === undefined) {
          this.reservation.totalBalance = parseInt(this.reservation.balance);
        } else {
          this.reservation.totalBalance = parseInt(window.localStorage.getItem('_oldPayExperienceFull'));
        }
        if (this.reservation.balance <= 0) {
          this.reservation.isFree = true;
          this.minutes = 2;
        }
        window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
        this.$store.commit(reservationTypes.mutations.setReservationsUser);
      }
    },
    experienceVendorFunction () {
      if (this.decoration) {
        if (this.decorationMultiple.length > 0) {
          this.reservation.decoration = true;
          this.reservation.decorations = this.decorationMultiple;
          return;
        }
        this.reservation.decoration = true;
        this.reservation.decorationId = this.decorationId;
      } else {
        this.reservation.decoration = false;
        this.reservation.decorationId = null;
        this.decorationDescription = null;
        this.decorationMultiple = [];
        this.reservation.decorations = [];
      }
      window.localStorage.setItem('_reservation', btoa(JSON.stringify(this.reservation)));
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
    },
    getDateForCronometer () {
      this.$store.dispatch({
        type: 'global:getServerTime',
        data: {
          timezone: this.vendor.timezone
        }
      })
        .then(({ serverTime }) => {
          let actual = this.$moment(serverTime).format('x');
          let inicio = this.$moment(atob(window.localStorage.getItem('_reservation_time'))).format('x');
          let difference = parseInt(actual - inicio);
          var minutesDifference = Math.floor(difference / 1000 / 60);
          difference -= minutesDifference * 1000 * 60;
          var secondsDifference = Math.floor(difference / 1000);
          let minutes = '00';
          let seconds = '00';
          if (minutesDifference >= 0) {
            minutes = minutesDifference <= 9 ? '0' + minutesDifference : minutesDifference;
          }
          if (secondsDifference >= 0) {
            seconds = secondsDifference <= 9 ? '0' + secondsDifference : secondsDifference;
          }
          this.minutes = this.minutes - minutes;
          this.index = seconds;
        }).catch(() => {
          let actual = this.$moment().format('x');
          let inicio = this.$moment(atob(window.localStorage.getItem('_reservation_time'))).format('x');
          let difference = parseInt(actual - inicio);
          var minutesDifference = Math.floor(difference / 1000 / 60);
          difference -= minutesDifference * 1000 * 60;
          var secondsDifference = Math.floor(difference / 1000);
          let minutes = '00';
          let seconds = '00';
          if (minutesDifference >= 0) {
            minutes = minutesDifference <= 9 ? '0' + minutesDifference : minutesDifference;
          }
          if (secondsDifference >= 0) {
            seconds = secondsDifference <= 9 ? '0' + secondsDifference : secondsDifference;
          }
          this.minutes = this.minutes - minutes;
          this.index = seconds;
        });
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.identificationTypes = data.data.map((i) => {
            return {
              name: i.name,
              value: i.id
            };
          });
        }
      });
    },
    handleTextareaClick () {
      this.placeHolderActive = true;
    },
    validatePhoneRegex (phone, indicative) {
      switch (true) {
        case !phone:
          this.validateTel = this.$t('checkoutValidations.phone.required');
          this.$emit('changeStatusValidatePhoneNumbers', true);
          break;

        case !/^\d+$/.test(phone):
          this.validateTel = this.$t('checkoutValidations.phone.numeric');
          this.$emit('changeStatusValidatePhoneNumbers', true);
          break;

        case indicative !== 57 && indicative !== 507 && phone.length < 7:
          this.validateTel = this.$t('checkoutValidations.phone.min');
          this.$emit('changeStatusValidatePhoneNumbers', true);
          break;

        case indicative !== 57 && indicative !== 507 && phone.length > 11:
          this.validateTel = this.$t('checkoutValidations.phone.max');
          this.$emit('changeStatusValidatePhoneNumbers', true);
          break;

        case indicative === 57 && phone.length !== 10:
          this.validateTel = this.$t('checkoutValidations.phone.min');
          this.$emit('changeStatusValidatePhoneNumbers', true);
          break;

        case indicative === 507 && phone.length < 7 && phone.length > 8:
          this.validateTel = this.$t('checkoutValidations.phone.min');
          this.$emit('changeStatusValidatePhoneNumbers', true);
          break;
        // Ningún error
        default:
          this.validateTel = '';
          this.$emit('changeStatusValidatePhoneNumbers', false);
          break;
      }
    },
    validatePhone () {
      if (!this.userData.identityDocument) {
        this.validateDoc = this.$t('checkoutValidations.documentNumber.required');
        this.$emit('changeStatusValidateDocumentNumber', true);
      } else {
        this.validateDoc = '';
        this.$emit('changeStatusValidateDocumentNumber', false);
      }
    },
    focusTextarea () {
      this.handleTextareaClick();
      this.$refs.commentsTextarea.focus();
    },
    handleSelectionNotification (type) {
      this.reservation.notification = type;
      switch (type) {
        case 'mail':
          this.emailNotificactionCheck = true;
          this.smsNotificactionCheck = false;
          this.whatsappNotificactionCheck = false;
          break;
        case 'sms':
          this.emailNotificactionCheck = false;
          this.smsNotificactionCheck = true;
          this.whatsappNotificactionCheck = false;
          break;
        case 'whatsapp':
          this.emailNotificactionCheck = false;
          this.smsNotificactionCheck = false;
          this.whatsappNotificactionCheck = true;
          break;
        default:
          break;
      }
    },
    getDecorations () {
      this.$http.get('/reservation/getDecorations/' + this.vendor.id).then(({ data }) => {
        this.allDecorationsAux = data.data;
        let index = 0;
        let chunkSize = 3;
        let arrayLength = data.data.length;
        let tempArray = [];
        if (this.params.activateMultipleDecorations === 1) {
          data.data = data.data.map(data => {
            data.selected = false;
            return data;
          });
        }
        for (index = 0; index < arrayLength; index += chunkSize) {
          let myChunk = data.data.slice(index, index + chunkSize);
          tempArray.push(myChunk);
        }
        this.decorationsAux = tempArray;
      }).catch(() => {
        this.loading = false;
        this.$buefy.toast.open({
          message: 'Error en la solicitud',
          type: 'is-danger'
        });
      }).finally(() => {
        const decorationSelected = this.allDecorationsAux.find((decoration) => decoration.id === this.reservation.decorationId);
        if (decorationSelected) {
          this.decorationId = decorationSelected.id;
          this.decorationDescription = decorationSelected.description;
        }
      });
    }
  },
  watch: {
    isLegalPerson () {
      this.reservation.isLegalPerson = this.isLegalPerson;
      window.localStorage.setItem(
        '_reservation',
        btoa(JSON.stringify(this.reservation))
      );
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
    },
    mainPlateState () {
      if (!this.mainPlateState) {
        this.reservation.mainPlates = [];
        window.localStorage.setItem(
          '_reservation',
          btoa(JSON.stringify(this.reservation))
        );
        this.$store.commit(reservationTypes.mutations.setReservationsUser);
        this.$emit('changeCheckedPlate', false);
      }
    },
    clientType () {
      this.reservation.clientType = this.clientType;
      const clientType = this.clientTypes.find(ct => ct.name === this.clientType);
      this.reservation.isActiveHuesped = clientType.extraInformation;
      this.reservation.extraDescription = clientType.extraDescription;
      this.isActiveHuesped = clientType.extraInformation;
      this.extraDescription = clientType.extraDescription;
    },
    mediaType () {
      this.reservation.mediaType = this.mediaType;
      const mediaType = this.clientTypes.find(ct => ct.name === this.mediaType);
      if (mediaType.extraDescription !== null && mediaType.extraDescription !== '') {
        this.validateExtraDescription = true;
        this.extraDescriptionMedia = mediaType.extraDescription;
      } else {
        this.validateExtraDescription = false;
      }
    },
    indicativo () {
      this.validateForm();
    },
    userLogged () {
      this.userData.phone = this.userLogged.phone;
    },
    'userData.subscribed': {
      handler () {
        this.$emit('validateCheck', this.userData.subscribed);
        if (this.userData.subscribed === true) {
          this.openModalSubcribe();
        } else {
          this.subcribe_option = null;
          this.userData.subscribed = null;
          this.reservation.subscribedTo = null;
          this.updatePeople();
        }
      }
    },
    'reservation.indicativo' () {
      if (this.reservation.indicativo != null) {
        /* this.changedPaisReservation(this.reservation.indicativo, null);
        this.validateIndicativeColombia(this.reservation.indicativo); */
      }
    },
    'userData.identityDocument' () {
      this.validatePhone();
    },
    birthDate () {
      this.generateBirthDate();
    },
    emailNotificactionCheck (value) {
      this.generalChecksSvg(value, 'email');
    },
    smsNotificactionCheck (value) {
      this.generalChecksSvg(value, 'sms');
    },
    whatsappNotificactionCheck (value) {
      this.generalChecksSvg(value, 'wssp');
    }
  },
  mounted () {
    this.$emit('validateCheck');
    this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
    setTimeout(() => {
      this.applyStrong = window.localStorage.getItem('applyStrong') || false;
      const ele = document.getElementById('emailTest');
      if (ele != null) {
        ele.addEventListener('textInput', (event) => {
          const char = event.data; // In our example = "a"
          if (char.indexOf(' ') >= 0) {
            event.preventDefault();
          }
          const keyCode = char.charCodeAt(0); // a = 97
          if (keyCode === 32) {
            event.preventDefault();
          }
        });
      }
      this.getActiveOptionsNumber();
      this.countForShow = true;
      this.generalChecksSvg(true, 'email');
    }, 800);
    // eslint-disable-next-line
    if (this.params.activeSelectorClient == '1' || this.params.activeHowMeetUs) {
      this.listClientType();
    }
    this.emailNotificactionCheck = true;
    this.reservation.notification = 'mail';
    if (this.reservation.alergies) {
      this.hasAlergy = true;
    }
    if (this.params.isActivePets && this.params.isActivePets === 1) {
      this.reservation.hasPets = false;
    }
    this.userData.name = this.userLogged.displayName;
    this.userData.displayName = this.userLogged.displayName;
    this.userData.phone = (this.userLogged.phone && this.userLogged.phone.trim() !== 'undefined') ? this.userLogged.phone : '';
    if (this.userData.phone === '') {
      this.userData.phone = (this.reservation.phone && this.reservation.phone.trim() !== 'undefined') ? this.reservation.phone : '';
    }
    this.userData.email = this.userLogged.email || this.reservation.email;
    if (this.user.identityDocument !== null) {
      this.userData.documentType = this.user.documentType;
      this.userData.identityDocument = this.user.identityDocument;
    }
    if (!this.user.identityDocument) {
      this.validatePhone();
    }
    if (this.user.birthday !== undefined) {
      if (this.user.birthday != null) {
        this.birthDate = this.$moment(this.user.birthday).toDate();
        let dates = this.user.birthday.split('-');
        this.birthDateMonth = parseInt(dates[1], 10);
        this.birthDateDay = parseInt(dates[2], 10);
      }
    }
    this.listPaisesReservation();
    this.getListMainPlate();
    this.getListExperience();
    this.getIdentification();
    let indicativo = this.$store.getters['whiteLabel:vendor'].params.indicativeRegion;
    if (indicativo === undefined || indicativo == null) {
      indicativo = 57;
    }
    /* this.indicativeParam(indicativo); */
    if (this.userLogged.indicativo !== null) {
      if (this.userLogged.indicativo !== undefined) {
        this.indicativo = this.userLogged.indicativo;
        this.changedPaisReservation(this.userLogged.indicativo, null, this.userLogged.bandera);
        this.reservation.indicativo = this.userLogged.indicativo;
        this.reservation.bandera = this.bandera;
      }
    } else if (this.reservation.indicativo !== null) {
      if (this.reservation.indicativo !== undefined) {
        this.indicativo = this.reservation.indicativo;
        this.changedPaisReservation(this.reservation.indicativo, this.reservation.bandera);
        // eslint-disable-next-line
        this.reservation.indicativo = this.reservation.indicativo;
        this.reservation.bandera = this.bandera;
      }
    } else {
      this.reservation.indicativo = null;
      this.reservation.bandera = null;
    }
    if (this.reservation.giftcardId != null) {
      this.giftcardId = this.reservation.giftcardId;
    }
    if (this.reservation.giftcardPrice > 0) {
      this.priceGiftCard = this.reservation.giftcardPrice;
    }
    this.reservation.seatingSectionId = this.selectedZone;
    window.localStorage.setItem(
      '_reservation',
      btoa(JSON.stringify(this.reservation))
    );
    if (this.reservation.isFree) {
      this.minutes = 2;
    } else {
      this.minutes = 9;
    }
    window.localStorage.removeItem('alert_timing');
    moment.tz.setDefault(this.vendor.timezone);
    this.getDateForCronometer();
    this.interval = window.setInterval(this.cronometer, 1000); // Frecuencia de actualización
    if (this.reservation.decoration) {
      this.decoration = true;
      // this.disabledDecoration = true
    }
    this.validateForm();
    this.getCelebrations();
    document.addEventListener('click', (evt) => {
      const flyoutElement = document.getElementById('Foutclick');
      let targetElement = evt.target;
      do {
        // eslint-disable-next-line
        if (targetElement != flyoutElement) {
          this.isContry();
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
    });
    this.validateIndicativeColombia(this.indicativo);
    this.cronometer();
    if (this.params.decorationActive) {
      let price = window.localStorage.getItem('_decoration_cost');
      if (this.decoration) {
        if (price) {
          this.$store.commit(reservationTypes.mutations.setDecorationCosto, parseInt(price));
          // this.decorationFunction()
        }
      }
      // this.decoration = false
      // this.reservation.decoration = false
      // this.disabledDecoration = false
    }
    if (this.vendor.id === 231) {
      if (window.localStorage.getItem('payReservations')) {
        this.costAdultMarea = window.localStorage.getItem('payReservations');
      }
      if (window.localStorage.getItem('payReservationBoys')) {
        this.costBoyMarea = window.localStorage.getItem('payReservationBoys');
      }
    }
    if (this.reservation.decorationId) {
      this.getDecorations();
    }
    this.intervalAux = setInterval(() => {
      if (document.getElementsByClassName('vc-weekday')[2] !== undefined) {
        document.getElementsByClassName('vc-weekday')[2].innerHTML = 'M';
        clearInterval(this.intervalAux);
      }
    }, 500);
    /* this.validateTelIndicative(this.reservation.indicativo); */
  },
  destroyed () {
    document.removeEventListener('click', (evt) => {
      const flyoutElement = document.getElementById('Foutclick');
      let targetElement = evt.target;
      do {
        // eslint-disable-next-line
        if (targetElement != flyoutElement) {
          this.isContry();
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
    }, true);
    clearInterval(this.interval);
    clearInterval(this.intervalAux);
  }
};
</script>

<style lang="scss">
  @import "@/assets/styles/checkout/_dataReservation.scss";
  .footer-logo {
    display: flex;
  }
</style>
